/**
 * @module PaymentIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { PAYMENT_METHOD_TYPES as iconKeys } from '../../utils';
import AchIcon from '../../assets/svg/payment/ach.svg';
import AmexIcon from '../../assets/svg/payment/amex.svg';
import ApplePayIcon from '../../assets/svg/payment/apple-pay.svg';
import CashAppIcon from '../../assets/svg/payment/cash-app.svg';
import CreditCardIcon from '../../assets/svg/payment/card.svg';
import DinersClubIcon from '../../assets/svg/payment/diners-club.svg';
import DiscoverIcon from '../../assets/svg/payment/discover.svg';
import GooglePayIcon from '../../assets/svg/payment/google-pay.svg';
import JcbIcon from '../../assets/svg/payment/jcb.svg';
import MastercardIcon from '../../assets/svg/payment/mastercard.svg';
import PayPalIcon from '../../assets/svg/payment/paypal.svg';
import SecurityIcon from '../../assets/svg/payment/security.svg';
import VenmoIcon from '../../assets/svg/payment/venmo.svg';
import VisaIcon from '../../assets/svg/payment/visa.svg';

/**
 * Represents a payment icon for the specified payment method.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the img element.
 * @param {number} [props.height] - Optional size multiplier (Example: size of 2 will return icon sized at 2x).
 * @param {PaymentMethod} props.paymentMethodData - The PaymentMethod data object.
 * @param {string} props.paymentMethodType - Optional payment method type, which if supplied, overrides any paymentMethodData supplied.
 *
 * @returns {React.ReactElement} The PaymentIcon component.
 */
export function PaymentIcon({
  className,
  height,
  paymentMethodData,
  paymentMethodType,
}) {
  const iconStyles = {
    height,
    width: 'auto',
  };
  let type =
    paymentMethodType?.toLowerCase() ||
    paymentMethodData?.attributes?.payment_method_type?.toLowerCase();
  let iconSrc;

  /**
   * Note: While part of this looks slightly repetitive due to the other cases
   * that check for specific credit card types, they are both needed just as a
   * failsafe in case there is any inconsistency in the payment method data,
   * namely if the actual `payment_method_type` includes the specific type of
   * credit card, not `Credit Card` with a corresponding `payment_type` that
   * otherwise holds the value of the card type.
   */
  switch (type) {
    case iconKeys.ACH.toLowerCase(): {
      iconSrc = AchIcon;
      break;
    }
    case iconKeys['ACH - Sign In'].toLowerCase(): {
      iconSrc = AchIcon;
      break;
    }
    case iconKeys['american express'].toLowerCase(): {
      iconSrc = AmexIcon;
      break;
    }
    case iconKeys.apple_pay.toLowerCase(): {
      iconSrc = ApplePayIcon;
      break;
    }
    case iconKeys['Credit Card'].toLowerCase(): {
      if (
        paymentMethodType &&
        paymentMethodType.toLowerCase() === 'credit card'
      ) {
        iconSrc = CreditCardIcon;
      } else {
        const ccPaymentType =
          paymentMethodData?.attributes?.payment_type?.toLowerCase();
        if (ccPaymentType === iconKeys['american express'].toLowerCase()) {
          iconSrc = AmexIcon;
        } else if (ccPaymentType === iconKeys.dinersclub.toLowerCase()) {
          iconSrc = DinersClubIcon;
        } else if (ccPaymentType === iconKeys.discover.toLowerCase()) {
          iconSrc = DiscoverIcon;
        } else if (ccPaymentType === iconKeys.jcb.toLowerCase()) {
          iconSrc = JcbIcon;
        } else if (ccPaymentType === iconKeys.mastercard.toLowerCase()) {
          iconSrc = MastercardIcon;
        } else if (ccPaymentType === iconKeys.visa.toLowerCase()) {
          iconSrc = VisaIcon;
        } else {
          iconSrc = CreditCardIcon;
        }
      }
      break;
    }
    case iconKeys.dinersclub.toLowerCase(): {
      iconSrc = DinersClubIcon;
      break;
    }
    case iconKeys.discover.toLowerCase(): {
      iconSrc = DiscoverIcon;
      break;
    }
    case iconKeys.cash_app.toLowerCase(): {
      iconSrc = CashAppIcon;
      break;
    }
    case iconKeys.google_pay.toLowerCase(): {
      iconSrc = GooglePayIcon;
      break;
    }
    case iconKeys.jcb.toLowerCase(): {
      iconSrc = JcbIcon;
      break;
    }
    case iconKeys.mastercard.toLowerCase(): {
      iconSrc = MastercardIcon;
      break;
    }
    case iconKeys.PayPal.toLowerCase(): {
      iconSrc = PayPalIcon;
      break;
    }
    case iconKeys.security.toLowerCase(): {
      iconSrc = SecurityIcon;
      break;
    }
    case iconKeys.venmo.toLowerCase(): {
      iconSrc = VenmoIcon;
      break;
    }
    case iconKeys.visa.toLowerCase(): {
      iconSrc = VisaIcon;
      break;
    }
    default: {
      // Note: Defaulting to security, and updating `type` to be the same to
      // guard against unsupported type being outputted as `alt` attribute.
      iconSrc = SecurityIcon;
      type = 'security';
      break;
    }
  }

  return (
    <img
      alt={type}
      className={className}
      data-testid="payment-icon"
      src={iconSrc}
      style={iconStyles}
    />
  );
}
