/**
 * @module GivingApi
 */
import { apiClient } from '../core';
import { API_PAYMENT_METHOD_TYPES, APP_CONFIG } from '../../utils';

/**
 * Retrieve preflight data for the user from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function preflight({ accessToken } = {}) {
  return apiClient.head({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/users/preflight`,
  });
}

/**
 * Retrieve campuses from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getCampuses({ accessToken } = {}) {
  const headers = {
    Accept: '*/*',
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return apiClient.get({
    options: {
      headers,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/campuses`,
  });
}

/**
 * Retrieve frequencies from Life.Church Giving API.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getFrequencies() {
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/frequencies`,
  });
}

/**
 * Retrieve funds from Life.Church Giving API.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getFunds() {
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/funds`,
  });
}

/**
 * Retrieve geolocation data from Life.Church geolocation service.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getGeolocation() {
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
      },
    },
    url: APP_CONFIG.geoUrl,
  });
}

/**
 * Retrieve payment history from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} [params.year] - Optional year to fetch. If none specified, returns current year.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getHistory({ accessToken, year } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  const yearParam = year ? `/${year}` : '';
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/history${yearParam}`,
  });
}

/**
 * Retrieve payment history PDF link for the specified year from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} params.year - The desired year for which to fetch.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getHistoryDownloads({ accessToken, year } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  const yearPath = year ? `/${year}` : '/2023';
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/history_downloads${yearPath}`,
  });
}

/**
 * Retrieve payment methods from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getPaymentMethods({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/payment_methods`,
  });
}

/**
 * Delete payment method with specified id from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {number|string} params.paymentMethodId - The unique payment method id value.
 *
 * @throws {Error} - Throws an error if access token or paymentMethodId are missing or invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function deletePaymentMethod({
  accessToken,
  paymentMethodId,
} = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (
    !paymentMethodId ||
    (typeof paymentMethodId !== 'string' && typeof paymentMethodId !== 'number')
  ) {
    throw new Error('Invalid payment method id provided.');
  }
  return apiClient.del({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/payment_methods/${paymentMethodId}`,
  });
}

/**
 * Update the payment method with specified id with Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} params.expiration - The amount of the donation (e.g. '23.00').
 * @param {string} params.name - The campus to which to attribute the donation (e.g. 'OKC').
 * @param {string} params.paymentMethodId - The unique payment method id value.
 * @param {'bank'|'card'} params.type - The payment method type. If 'card' is provided (the default), a valid `expiration` prop is also required.
 *
 * @throws {Error} - Throws an error if any required data is missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function updatePaymentMethod({
  accessToken,
  expiration,
  name,
  paymentMethodId,
  type = API_PAYMENT_METHOD_TYPES.update.card,
}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (
    !paymentMethodId ||
    (typeof paymentMethodId !== 'string' && typeof paymentMethodId !== 'number')
  ) {
    throw new Error('Invalid payment method id provided.');
  }
  if (
    (!expiration || typeof expiration !== 'string') &&
    type === API_PAYMENT_METHOD_TYPES.update.card
  ) {
    throw new Error('Invalid expiration provided.');
  }
  if (name === null || name === undefined || typeof name !== 'string') {
    throw new Error('Invalid name provided.');
  }
  const params = { name };
  if (type === API_PAYMENT_METHOD_TYPES.update.card) {
    params.expiration = expiration;
  }
  return apiClient.patch({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    params,
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/payment_methods/${paymentMethodId}`,
  });
}

/**
 * Retrieve status of the Life.Church Giving API.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getStatus() {
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/status`,
  });
}

/**
 * Retrieve scheduled gift with specified id from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {number|string} params.giftId - The unique scheduled gift id value.
 *
 * @throws {Error} - Throws an error if access token or giftId are missing or invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getScheduledGift({ accessToken, giftId } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (!giftId || (typeof giftId !== 'string' && typeof giftId !== 'number')) {
    throw new Error('Invalid gift id provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/subscriptions/${giftId}`,
  });
}

/**
 * Retrieve payment methods from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getScheduledGifts({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/subscriptions`,
  });
}

/**
 * Update the scheduled gift with specified id with Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} params.amount - The amount of the donation (e.g. '23.00').
 * @param {string} params.campus - The campus to which to attribute the donation (e.g. 'OKC').
 * @param {string} params.frequency - The frequency of the donation (e.g. '2monthly').
 * @param {string} params.fund - The fund to which to attribute the donation (e.g. 'Campus Giving').
 * @param {number|string} params.giftId - The unique scheduled gift id value.
 * @param {number} params.nextPaymentDate - The Unix timestamp of the next payment date (e.g. 1499878800).
 * @param {number|string} params.paymentMethod - The payment method id to which to attribute the donation (e.g. '6778').
 *
 * @throws {Error} - Throws an error if any required data is missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function updateScheduledGift({
  accessToken,
  amount,
  campus,
  frequency,
  fund,
  giftId,
  nextPaymentDate,
  paymentMethod,
}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (!amount || typeof amount !== 'string') {
    throw new Error('Invalid amount provided.');
  }
  if (!campus || typeof campus !== 'string') {
    throw new Error('Invalid campus provided.');
  }
  if (!frequency || typeof frequency !== 'string') {
    throw new Error('Invalid frequency provided.');
  }
  if (!fund || typeof fund !== 'string') {
    throw new Error('Invalid fund provided.');
  }
  if (!giftId || (typeof giftId !== 'string' && typeof giftId !== 'number')) {
    throw new Error('Invalid gift id provided.');
  }
  if (!nextPaymentDate || typeof nextPaymentDate !== 'number') {
    throw new Error('Invalid next payment date provided.');
  }
  if (
    !paymentMethod ||
    (typeof paymentMethod !== 'string' && typeof paymentMethod !== 'number')
  ) {
    throw new Error('Invalid payment method provided.');
  }
  return apiClient.patch({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    params: {
      amount,
      campus,
      frequency,
      fund,
      next_payment_date: nextPaymentDate,
      payment_method: paymentMethod,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/subscriptions/${giftId}`,
  });
}

/**
 * Delete scheduled gift with specified id from Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {number|string} params.giftId - The unique scheduled gift id value.
 *
 * @throws {Error} - Throws an error if access token or giftId are missing or invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function deleteScheduledGift({ accessToken, giftId } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (!giftId || (typeof giftId !== 'string' && typeof giftId !== 'number')) {
    throw new Error('Invalid gift id provided.');
  }
  return apiClient.del({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/subscriptions/${giftId}`,
  });
}

/**
 * Retrieve a financial connections session token.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getFinancialConnectionsSession({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    params: {},
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/financial_connections_session`,
  });
}

/**
 * Create donation for logged out user to Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {Address} params.address - The user's address data.
 * @param {boolean} params.allowNull - Boolean flag denoting whether or not to allow null values to be sent.
 * @param {string} params.amount - The amount of the donation (e.g. '23.00').
 * @param {string} params.campus - The campus to which to attribute the donation (e.g. 'OKC').
 * @param {string} params.currency - The currency code value (e.g. 'USD').
 * @param {string} params.email - The user's email address.
 * @param {string} params.frequency - The frequency of the donation (e.g. '2monthly').
 * @param {string} params.fund - The fund to which to attribute the donation (e.g. 'Campus Giving').
 * @param {string} params.last4 - The last four digits of the user's credit card (e.g. '4242').
 * @param {string} params.name - The user's name (e.g. 'John Doe').
 * @param {number} params.paymentDate - The Unix timestamp of the donation (e.g. 1499878800).
 * @param {string} params.paymentToken - The payment token value (e.g. 'pm_abc123').
 * @param {string} params.smsPhoneNumber - The user's mobile phone number (e.g. '4156885433').
 * @param {string} params.tokenizationMethod - The tokenization method used (e.g. 'apple_pay').
 *
 * @throws {Error} - Throws an error if any required data is missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export const postLoggedOutDonation = async ({
  address,
  allowNull,
  amount,
  campus,
  currency,
  email,
  frequency,
  fund,
  last4,
  name,
  paymentDate,
  paymentToken,
  smsPhoneNumber,
  tokenizationMethod,
} = {}) => {
  if (!allowNull) {
    if (
      !address ||
      typeof address !== 'object' ||
      Object.keys(address).length < 1
    ) {
      throw new Error('Invalid address provided.');
    }
    if (!email || typeof email !== 'string') {
      throw new Error('Invalid email provided.');
    }
    if (!name || typeof name !== 'string') {
      throw new Error('Invalid name provided.');
    }
    if (!smsPhoneNumber || typeof smsPhoneNumber !== 'string') {
      throw new Error('Invalid SMS phone number provided.');
    }
  }
  if (!amount || typeof amount !== 'string') {
    throw new Error('Invalid amount provided.');
  }
  if (!campus || typeof campus !== 'string') {
    throw new Error('Invalid campus provided.');
  }
  if (!currency || typeof currency !== 'string') {
    throw new Error('Invalid currency provided.');
  }
  if (!frequency || typeof frequency !== 'string') {
    throw new Error('Invalid frequency provided.');
  }
  if (!fund || typeof fund !== 'string') {
    throw new Error('Invalid fund provided.');
  }
  if (!last4 || typeof last4 !== 'string') {
    throw new Error('Invalid last4 provided.');
  }
  if (!paymentDate || typeof paymentDate !== 'number') {
    throw new Error('Invalid payment date provided.');
  }
  if (!paymentToken || typeof paymentToken !== 'string') {
    throw new Error('Invalid payment token provided.');
  }
  if (!tokenizationMethod || typeof tokenizationMethod !== 'string') {
    throw new Error('Invalid tokenization method provided.');
  }
  return apiClient.post({
    options: {
      headers: {
        Accept: '*/*',
        'x-api-key': process.env.GIVING_API_KEY_LOGGED_OUT,
      },
    },
    params: {
      address:
        /* istanbul ignore next */ process.env.GIVING_API_VERSION === 'v2'
          ? JSON.stringify(address)
          : address,
      amount,
      campus,
      currency,
      email,
      frequency,
      fund,
      last4,
      name,
      payment_date: Math.floor(paymentDate),
      payment_token: paymentToken,
      sms_phone_number: smsPhoneNumber,
      tokenization_method: tokenizationMethod,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/logged_out_donations`,
  });
}; // NOSONAR

/**
 * Create donation to Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} params.amount - The amount of the donation (e.g. '23.00').
 * @param {string} params.campus - The campus to which to attribute the donation (e.g. 'OKC').
 * @param {string} params.frequency - The frequency of the donation (e.g. '2monthly').
 * @param {string} params.fund - The fund to which to attribute the donation (e.g. 'Campus Giving').
 * @param {number} params.paymentDate - The Unix timestamp of the donation (e.g. 1499878800).
 * @param {string} params.paymentMethod - The payment method id to which to attribute the donation (e.g. 6778).
 *
 * @throws {Error} - Throws an error if any required data is missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function postDonation({
  accessToken,
  amount,
  campus,
  frequency,
  fund,
  paymentDate,
  paymentMethod,
} = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (!amount || typeof amount !== 'string') {
    throw new Error('Invalid amount provided.');
  }
  if (!campus || typeof campus !== 'string') {
    throw new Error('Invalid campus provided.');
  }
  if (!frequency || typeof frequency !== 'string') {
    throw new Error('Invalid frequency provided.');
  }
  if (!fund || typeof fund !== 'string') {
    throw new Error('Invalid fund provided.');
  }
  if (!paymentDate || typeof paymentDate !== 'number') {
    throw new Error('Invalid payment date provided.');
  }
  if (!paymentMethod || typeof paymentMethod !== 'string') {
    throw new Error('Invalid payment method provided.');
  }
  return apiClient.post({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    params: {
      amount,
      campus,
      frequency,
      fund,
      payment_date: Math.floor(paymentDate),
      payment_method: paymentMethod,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/donations`,
  });
}

/**
 * Create payment method at Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {string} params.paymentToken - The one-time payment token from Stripe to which to attribute to the payment method (e.g. 'tok_1DS3pz4I05yfRIfHLOYVBi8T').
 *
 * @throws {Error} - Throws an error if any required data is missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function createPaymentMethod({ accessToken, paymentToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (!paymentToken || typeof paymentToken !== 'string') {
    throw new Error('Invalid payment token provided.');
  }
  return apiClient.post({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    params: {
      payment_token: paymentToken,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/payment_methods`,
  });
}

/**
 * Verify response at Life.Church Giving API.
 *
 * @param {object} params - The function params object.
 * @param {string} params.remoteIp - The user's public IP address.
 * @param {string} params.response - The response value to verify.
 * @param {string} params.siteKey - The reCAPTCHA public site key.
 * @param {string} params.type - The reCAPTCHA type (e.g. 'recaptcha_v2').
 *
 * @returns {Promise<object>} The API response object.
 */
export async function verifyResponse({
  remoteIp,
  response,
  siteKey,
  type,
} = {}) {
  if (!remoteIp || typeof remoteIp !== 'string') {
    throw new Error('Invalid remoteIp value provided.');
  }
  if (!response || typeof response !== 'string') {
    throw new Error('Invalid response value provided.');
  }
  if (!siteKey || typeof siteKey !== 'string') {
    throw new Error('Invalid siteKey value provided.');
  }
  if (!type || typeof type !== 'string') {
    throw new Error('Invalid type value provided.');
  }
  return apiClient.post({
    options: {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded;',
      },
    },
    params: {
      remoteip: remoteIp,
      response,
      sitekey: siteKey,
      type,
    },
    url: `${process.env.GIVING_API_DOMAIN}/${process.env.GIVING_API_VERSION}/verifications/verify`,
  });
}
