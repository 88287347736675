/**
 * @module Constants
 */

// Sizes
export const BREAKPOINTS = Object.freeze({
  lg: 1024,
  md: 640,
  xl: 1200,
});

export const CALLBACKS = Object.freeze({
  auth0: {
    path: '/give-form/callback',
  },
});

// Environment
export const ENVIRONMENT = {
  dev: process.env.NODE_ENV === 'development',
  development: process.env.NODE_ENV === 'development',
  prod: process.env.NODE_ENV === 'production',
  production: process.env.NODE_ENV === 'production',
  review: process.env.NODE_ENV === 'review',
  staging: process.env.NODE_ENV === 'staging',
};

export const APP_CONFIG = {
  baseUrl: `https://${
    !ENVIRONMENT.production ? 'staging.' : 'www.'
  }life.church`,
  braintreePaymentProviders: process.env.BRAINTREE_PAYMENT_PROVIDERS
    ? process.env.BRAINTREE_PAYMENT_PROVIDERS.split('|')
    : [],
  braintreeTokenizationKey: process.env.BRAINTREE_TOKENIZATION_KEY,
  currentLocationThresholdMeters: parseInt(
    process.env.CURRENT_LOCATION_THRESHOLD_METERS || 0,
    10,
  ),
  excludedEditablePaymentMethodTypes: process.env
    .EDITABLE_PAYMENT_METHOD_TYPE_EXCLUSIONS
    ? process.env.EDITABLE_PAYMENT_METHOD_TYPE_EXCLUSIONS.split('|')
    : [],
  geoUrl: 'https://geo.life.church/where',
  givingBaseUrl: process.env.GIVING_BASE_URL,
  givingHistoryCacheDuration:
    parseInt(process.env.GIVING_HISTORY_CACHE_DURATION, 10) || 60,
  givingHistoryStartYear:
    parseInt(process.env.GIVING_HISTORY_START_YEAR, 10) || 2017,
  includeApplePay:
    (process.env.INCLUDE_APPLE_PAY &&
      process.env.INCLUDE_APPLE_PAY.toString() === 'true') ||
    false,
  includeFinancialConnections:
    (process.env.INCLUDE_FINANCIAL_CONNECTIONS &&
      process.env.INCLUDE_FINANCIAL_CONNECTIONS.toString() === 'true') ||
    false,
  includeLoggedOutDonation:
    (process.env.INCLUDE_LOGGED_OUT_DONATION &&
      process.env.INCLUDE_LOGGED_OUT_DONATION.toString() === 'true') ||
    false,
  includeLogOut:
    (process.env.INCLUDE_LOG_OUT &&
      process.env.INCLUDE_LOG_OUT.toString() === 'true') ||
    false,
  reCaptchaVersion: process.env.RECAPTCHA_VERSION,
};

export const BADGES = Object.freeze({
  error: {
    bgColor: '#ffe9e1',
    color: '#ad3612',
  },
  warning: {
    bgColor: '#fff7e0',
    color: '#916700',
  },
});

export const CONTENT_MODES = Object.freeze({
  dangerouslySetInnerHTML: 'dangerouslySetInnerHTML',
  default: 'default',
});

export const ICON_OVERRIDES = Object.freeze({
  back: 'back',
});

export const API_PAYMENT_METHOD_TYPES = Object.freeze({
  update: {
    bank: 'bank',
    card: 'card',
  },
});

export const BRAINTREE_PAYMENT_PROVIDERS = Object.freeze({
  paypal: 'paypal',
  venmo: 'venmo',
});

export const BRAINTREE_ERRORS = Object.freeze({
  tokenize: {
    payPal: {
      accountTokenizationFailed: 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED',
      flowFailed: 'PAYPAL_FLOW_FAILED',
      popupClosed: 'PAYPAL_POPUP_CLOSED',
    },
    venmo: {
      appCanceled: 'VENMO_APP_CANCELED',
      canceled: 'VENMO_CANCELED',
    },
  },
});

export const PAYMENT_METHOD_STATUSES = Object.freeze({
  expired: 'Expired',
  invalid: 'Invalid',
  valid: 'Valid',
});

export const MODAL_MODES = Object.freeze({
  add: 'add',
  confirm: 'confirm',
  detail: 'detail',
  edit: 'edit',
  emailAddress: 'email-address',
  givingStatements: 'giving-statements',
  mailingAddress: 'mailing-address',
  main: 'main',
  manage: 'manage',
  newBank: 'new-bank',
  newCard: 'new-card',
  select: 'select',
  update: 'update',
  yearSelect: 'year-select',
});

/**
 * Convenience variable to provide modal id values and auth requirements.
 * Note: modal queryParam values are all-lowercase, character-only values so
 * logic can better handle varying cases, space/hyphen/underscore usage, etc.
 */
export const MODALS = Object.freeze({
  confirmation: {
    id: 'confirmation',
    isAuthRequired: false,
    queryParams: ['confirmation'],
  },
  frequency: {
    id: 'frequency',
    isAuthRequired: false,
    queryParams: ['frequency', 'frequencies'],
  },
  funds: {
    id: 'funds',
    isAuthRequired: false,
    queryParams: ['fund', 'funds'],
  },
  givingHistory: {
    id: 'givingHistory',
    isAuthRequired: true,
    queryParams: ['givinghistory'],
  },
  givingMenu: {
    id: 'givingMenu',
    isAuthRequired: true,
    queryParams: ['givingmenu'],
  },
  help: {
    id: 'help',
    isAuthRequired: false,
    queryParams: ['help'],
  },
  location: {
    id: 'location',
    isAuthRequired: false,
    queryParams: ['campus', 'campuses', 'location', 'locations'],
  },
  manageGiving: {
    id: 'manageGiving',
    isAuthRequired: true,
    queryParams: ['manage-giving'],
  },
  managePaymentMethod: {
    id: 'managePaymentMethod',
    isAuthRequired: true,
    queryParams: ['manage-payment-method'],
  },
  paperlessPreference: {
    id: 'paperlessPreference',
    isAuthRequired: true,
    queryParams: ['paperless', 'paperlesspreference', 'paperless-preference'],
  },
  paymentMethod: {
    id: 'paymentMethod',
    isAuthRequired: true,
    queryParams: ['addpaymentmethod', 'paymentmethod', 'paymentmethods'],
  },
  processDate: {
    id: 'processDate',
    isAuthRequired: false,
    queryParams: ['paymentdate', 'processdate'],
  },
});

export const POST_DONATION_CTA_ACTION_TYPES = Object.freeze({
  openManageGiving: 'OPEN_MANAGE_GIVING',
  openRecurring: 'OPEN_RECURRING',
});

export const ERROR_MODES = Object.freeze({
  error: 'error',
  maintenance: 'maintenance',
  outage: 'outage',
});

export const FORM_MODES = Object.freeze({
  main: 'main',
  manageGift: 'manage-gift',
  managePaymentMethod: 'manage-payment-method',
});

export const LAYOUT_MODES = Object.freeze({
  horizontal: 'horizontal',
  vertical: 'vertical',
});

export const PAYMENT_METHOD_TYPES = Object.freeze({
  ACH: 'ACH',
  'ACH - Sign In': 'ACH - Sign In',
  'american express': 'american express',
  apple_pay: 'apple_pay',
  cash_app: 'cash_app',
  'Credit Card': 'Credit Card',
  dinersclub: 'dinersclub',
  discover: 'discover',
  google_pay: 'google_pay',
  jcb: 'jcb',
  mastercard: 'mastercard',
  PayPal: 'PayPal',
  security: 'security',
  venmo: 'venmo',
  visa: 'visa',
});

export const EDITABLE_PAYMENT_METHOD_TYPES = Object.freeze({
  ACH: 'ACH',
  'ACH - Sign In': 'ACH - Sign In',
  'american express': 'american express',
  'Credit Card': 'Credit Card',
  dinersclub: 'dinersclub',
  discover: 'discover',
  jcb: 'jcb',
  mastercard: 'mastercard',
  security: 'security',
  visa: 'visa',
});

export const TOKENIZATION_METHODS = Object.freeze({
  applePay: 'apple_pay',
  googlePay: 'google_pay',
});

export const APPLE_PAY_PAYMENT_METHOD_OBJECT = {
  attributes: {
    display_label: 'Apple Pay',
    payment_method_type: PAYMENT_METHOD_TYPES.apple_pay,
    payment_type: PAYMENT_METHOD_TYPES.apple_pay,
  },
  id: PAYMENT_METHOD_TYPES.apple_pay,
  type: PAYMENT_METHOD_TYPES.apple_pay,
};

export const GOOGLE_PAY_PAYMENT_METHOD_OBJECT = {
  attributes: {
    display_label: 'Google Pay',
    payment_method_type: PAYMENT_METHOD_TYPES.google_pay,
    payment_type: PAYMENT_METHOD_TYPES.google_pay,
  },
  id: PAYMENT_METHOD_TYPES.google_pay,
  type: PAYMENT_METHOD_TYPES.google_pay,
};

export const PAYPAL_PAYMENT_METHOD_OBJECT = {
  attributes: {
    display_label: 'New PayPal',
    name: 'Account Email',
    payment_method_type: PAYMENT_METHOD_TYPES.PayPal,
    payment_type: PAYMENT_METHOD_TYPES.PayPal,
  },
  id: PAYMENT_METHOD_TYPES.PayPal,
  type: PAYMENT_METHOD_TYPES.PayPal,
};

export const SMART_PAY_PROVIDERS = Object.freeze({
  [PAYMENT_METHOD_TYPES.apple_pay]: APPLE_PAY_PAYMENT_METHOD_OBJECT,
  [PAYMENT_METHOD_TYPES.google_pay]: GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
});

export const REQUEST_HEADERS = {
  contentType: {
    name: 'Content-Type',
    values: {
      applicationJson: 'application/json;',
      multipartFormData: 'multipart/form-data;',
      xWwwFormUrlEncoded: 'application/x-www-form-urlencoded;',
    },
  },
};

export const COUNTRIES = Object.freeze([
  {
    code: 'US',
    name: 'United States',
  },
]);

export const US_STATES = Object.freeze([
  {
    code: 'AL',
    name: 'Alabama',
  },
  {
    code: 'AK',
    name: 'Alaska',
  },
  {
    code: 'AZ',
    name: 'Arizona',
  },
  {
    code: 'AR',
    name: 'Arkansas',
  },
  {
    code: 'CA',
    name: 'California',
  },
  {
    code: 'CO',
    name: 'Colorado',
  },
  {
    code: 'CT',
    name: 'Connecticut',
  },
  {
    code: 'DE',
    name: 'Delaware',
  },
  {
    code: 'DC',
    name: 'District of Columbia',
  },
  {
    code: 'FL',
    name: 'Florida',
  },
  {
    code: 'GA',
    name: 'Georgia',
  },
  {
    code: 'HI',
    name: 'Hawaii',
  },
  {
    code: 'ID',
    name: 'Idaho',
  },
  {
    code: 'IL',
    name: 'Illinois',
  },
  {
    code: 'IN',
    name: 'Indiana',
  },
  {
    code: 'IA',
    name: 'Iowa',
  },
  {
    code: 'KS',
    name: 'Kansas',
  },
  {
    code: 'KY',
    name: 'Kentucky',
  },
  {
    code: 'LA',
    name: 'Louisiana',
  },
  {
    code: 'ME',
    name: 'Maine',
  },
  {
    code: 'MD',
    name: 'Maryland',
  },
  {
    code: 'MA',
    name: 'Massachusetts',
  },
  {
    code: 'MI',
    name: 'Michigan',
  },
  {
    code: 'MN',
    name: 'Minnesota',
  },
  {
    code: 'MS',
    name: 'Mississippi',
  },
  {
    code: 'MO',
    name: 'Missouri',
  },
  {
    code: 'MT',
    name: 'Montana',
  },
  {
    code: 'NE',
    name: 'Nebraska',
  },
  {
    code: 'NV',
    name: 'Nevada',
  },
  {
    code: 'NH',
    name: 'New Hampshire',
  },
  {
    code: 'NJ',
    name: 'New Jersey',
  },
  {
    code: 'NM',
    name: 'New Mexico',
  },
  {
    code: 'NY',
    name: 'New York',
  },
  {
    code: 'NC',
    name: 'North Carolina',
  },
  {
    code: 'ND',
    name: 'North Dakota',
  },
  {
    code: 'OH',
    name: 'Ohio',
  },
  {
    code: 'OK',
    name: 'Oklahoma',
  },
  {
    code: 'OR',
    name: 'Oregon',
  },
  {
    code: 'PA',
    name: 'Pennsylvania',
  },
  {
    code: 'RI',
    name: 'Rhode Island',
  },
  {
    code: 'SC',
    name: 'South Carolina',
  },
  {
    code: 'SD',
    name: 'South Dakota',
  },
  {
    code: 'TN',
    name: 'Tennessee',
  },
  {
    code: 'TX',
    name: 'Texas',
  },
  {
    code: 'UT',
    name: 'Utah',
  },
  {
    code: 'VT',
    name: 'Vermont',
  },
  {
    code: 'VA',
    name: 'Virginia',
  },
  {
    code: 'WA',
    name: 'Washington',
  },
  {
    code: 'WV',
    name: 'West Virginia',
  },
  {
    code: 'WI',
    name: 'Wisconsin',
  },
  {
    code: 'WY',
    name: 'Wyoming',
  },
]);

/**
 * Convenience size map containing calculations and sizes for elements in
 * various scenarios. For instance, `amount` is a map with a structure of the
 * number of total digits (not including commas) before the decimal, within each
 * having the number of decimal points (either 0 or 1), and how many decimal
 * digits (0, 1, or 2):
 *
 * Examples:
 * • amount.5.1.1 equates to a number with 5 digits before the decimal, with 1
 * decimal and 1 digit after the decimal (e.g. 12,345.6).
 * • amount.7.1.2 equates to a number with 7 digits before the decimal, with 1
 * decimal and 2 digits after the decimal (e.g. 1,234,567.89).
 */
export const SIZE_MAP = Object.freeze({
  amount: {
    0: {
      0: {
        0: 45,
      },
      1: {
        0: 18,
        1: 62,
        2: 106,
      },
    },
    1: {
      0: {
        0: 44,
      },
      1: {
        0: 61,
        1: 104,
        2: 148,
      },
    },
    2: {
      0: {
        0: 88,
      },
      1: {
        0: 104,
        1: 148,
        2: 192,
      },
    },
    3: {
      0: {
        0: 131,
      },
      1: {
        0: 148,
        1: 192,
        2: 236,
      },
    },
    4: {
      0: {
        0: 192,
      },
      1: {
        0: 209,
        1: 253,
        2: 297,
      },
    },
    5: {
      0: {
        0: 236,
      },
      1: {
        0: 253,
        1: 297,
        2: 340,
      },
    },
    6: {
      0: {
        0: 280,
      },
      1: {
        0: 297,
        1: 340,
        2: 384,
      },
    },
    7: {
      0: {
        0: 340,
      },
      1: {
        0: 357,
        1: 401,
        2: 445,
      },
    },
    maxFontSize: {
      input: 72,
      label: 32,
    },
  },
});

export const STRIPE_CARD_ELEMENT_OPTIONS = Object.freeze({
  style: {
    base: {
      '::placeholder': {
        color: '#c6c6c6',
      },
      color: '#000000',
      fontFamily:
        '"Proxima Nova", proxima_nova, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    complete: {
      color: '#09c1a1',
      iconColor: '#09c1a1',
    },
    invalid: {
      color: '#e33300',
      iconColor: '#e33300',
    },
  },
});

export const STRIPE_EVENTS = Object.freeze({
  complete: {
    fail: 'fail',
    success: 'success',
  },
});

export const STATUS_TYPES = Object.freeze({
  error: 'error',
  idle: 'idle',
  info: 'info',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved',
  success: 'success',
  warning: 'warning',
});

export const HTTP_METHODS = Object.freeze({
  del: 'DELETE',
  get: 'GET',
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
});

export const LOCAL_STORAGE_KEYS = Object.freeze({
  path: 'LC:WebGiving:path',
  userGivingData: 'LC:WebGiving:userGivingData',
});

// Log Configuration
export const LOG_CONFIG = {
  colorize:
    process.env.LOG_OPTIONS_COLORIZE &&
    process.env.LOG_OPTIONS_COLORIZE.toString() === 'true',
  includeTimestamp:
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP &&
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP.toString() === 'true',
  label: process.env.LOG_OPTIONS_LABEL || 'Life.Church Web Giving',
  printOutput:
    (process.env.LOG_OPTIONS_PRINT_OUTPUT &&
      process.env.LOG_OPTIONS_PRINT_OUTPUT.toString() === 'true') ||
    !ENVIRONMENT.production,
};

export const CONSENT_MANAGER_CONFIG = {
  bannerContent:
    "<span>We use cookies and other similar technologies to collect data to improve your experience on our site. By using our website, you're agreeing to the collection of data as described in our <a href='https://life.church/privacy' target='_blank' title='View the Life.Church Privacy Policy'>Privacy Policy</a>.</span>",
  bannerSubContent: 'You can change your preferences any time.',
  buttonLabels: {
    allowAll: 'Allow All',
    denyAll: 'Deny All',
    save: 'Save',
  },
  categories: {
    advertising: {
      categories: ['Advertising'],
      description:
        'Advertising cookies are used to provide relevant and interest-based content to you. We may share this information with advertisers or use it to understand your interests better. For example, advertising cookies may be used to share data with advertisers, making ads more relevant to you and allowing you to share certain pages with social networks or post comments.',
      order: 2,
      title: 'Advertising',
    },
    analyticsMarketing: {
      categories: ['Analytics', 'Email Marketing'],
      description:
        'Analytics tracking is done to evaluate and understand user behavior to provide you with a more relevant browsing experience or personalize the content of our site.',
      order: 1,
      title: 'Marketing and Analytics',
    },
    functionality: {
      categories: ['Functionality'],
      description:
        'Functional cookies are used to provide and support core functionality for the website.',
      order: 3,
      title: 'Functionality',
    },
  },
  categoryMap: {
    Advertising: 'advertising',
    Analytics: 'analyticsMarketing',
    'Email Marketing': 'analyticsMarketing',
    Functionality: 'functionality',
  },
  customDestinations: [
    {
      category: 'Functionality',
      description:
        'Functional cookies are used to provide and support core functionality for the website.',
      id: 'Functionality',
      name: 'Functionality',
      website: '',
    },
  ],
  disclaimer:
    '<p><i>Saving changed settings may reload the page to ensure your preferences take effect.</i></p>',
  footerLinkCTA: 'Cookie Preferences',
  preferencesDialogContent:
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized content, and increase the overall performance of our site.',
  preferencesDialogTitle: 'Website Data Collection Preferences',
  productionDestinationOverrides: [
    {
      category: 'Analytics',
      description:
        'Amplitude is an event tracking and segmentation platform for your web and mobile apps. By analyzing the actions your users perform, you can gain a better understanding to drive retention, engagement, and conversion.',
      id: 'Actions Amplitude',
      name: 'Amplitude (Actions)',
      website: 'https://amplitude.com',
    },
    {
      category: 'Email Marketing',
      description: 'Send events server-side to the Braze REST API.',
      id: 'Braze Cloud Mode (Actions)',
      name: 'Braze Cloud Mode (Actions)',
      website: 'https://www.braze.com/',
    },
    {
      category: 'Advertising',
      description:
        'The Facebook Pixel integration lets you measure and optimize the performance of your Facebook Ads.',
      id: 'Facebook Pixel',
      name: 'Facebook Pixel',
      website: 'https://developers.facebook.com/docs/facebook-pixel',
    },
    {
      category: 'Analytics',
      description:
        'Google Analytics 4 (GA4) is Google’s new Analytics property, which you can use for both websites and apps. Google Analytics 4 has machine learning at its core to automatically surface helpful insights and give you a complete understanding of your customers across devices and platforms. When you have Segment installed, you can make efficient use of your existing tracking implementation by using Segment to fulfill your data collection needs across all your tools that integrate with Segment, including Google Analytics 4. The Google Analytics 4 Web destination works on the client by loading gtag.js for you.',
      id: 'Google Analytics 4 Web',
      name: 'Google Analytics 4 Web',
      website: 'https://support.google.com/analytics/answer/10089681',
    },
    {
      category: 'Analytics',
      description:
        'Mixpanel is an event tracking tool targeted at web apps with lots of features: funnel, retention and people tracking; advanced segmentation; and sending email and notifications. ',
      id: 'Mixpanel (Actions)',
      name: 'Mixpanel (Actions)',
      website: 'http://mixpanel.com',
    },
  ],
};

export const CONSENT_MANAGER_THIRD_PARTY_SERVICES = {
  google: {
    destinations: ['Google Analytics', 'Google Analytics 4 Web'],
    enabled: false,
    name: 'Google',
    scriptSrcUrls: ['googleoptimize.com', 'googletagmanager.com'],
  },
};
