/**
 * @module AddPaymentMethodScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { ListItem } from '../../../../ListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import { APP_CONFIG, STRINGS } from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents the HTML markup and contents for the payment method modal when set to "add" mode.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {boolean} [props.includePayPal] - Boolean flag denoting whether or not to include PayPal button and logic (Default: true, inherited from parent component).
 * @param {boolean} [props.includeSmartPay] - Boolean flag denoting whether or not to include Smart Pay options and logic (Default: true, inherited from parent component).
 * @param {boolean} [props.includeVenmo] - Boolean flag denoting whether or not to include Venmo button and logic (Default: false, inherited from parent component).
 * @param {Function} [props.onAddBankAccountManualClick] - Handler function for manually add bank account list item click event.
 * @param {Function} [props.onAddBankAccountSignInClick] - Handler function for sign in to add bank account list item click event.
 * @param {Function} [props.onAddCardClick] - Handler function for add card list item click event.
 * @param {Function} [props.onPayPalClick] - Handler function for PayPal button click event.
 * @param {Function} [props.onVenmoClick] - Handler function for Venmo button click event.
 *
 * @returns {React.ReactElement} The AddPaymentMethodScreen component.
 */
export function AddPaymentMethodScreen({
  className,
  includePayPal,
  includeSmartPay,
  includeVenmo,
  onAddBankAccountManualClick,
  onAddBankAccountSignInClick,
  onAddCardClick,
  onPayPalClick,
  onVenmoClick,
}) {
  return (
    <div className={className}>
      <div className="list add-payment-method">
        {
          /* istanbul ignore next */ APP_CONFIG.includeFinancialConnections ? (
            <ListItem
              className="mb-16"
              data-testid="add-bank-account-payment-method"
              onClick={onAddBankAccountSignInClick}
              startIcon={<PaymentIcon height={42} paymentMethodType="ACH" />}
              title={paymentMethodStrings.add.labels.bankAccountSignIn}
              titleProps={{ primary: true }}
            />
          ) : null
        }
        <ListItem
          className="mb-16"
          data-testid="add-bank-account-payment-method"
          onClick={onAddBankAccountManualClick}
          startIcon={<PaymentIcon height={42} paymentMethodType="ACH" />}
          title={paymentMethodStrings.add.labels.bankAccount}
          titleProps={{ primary: true }}
        />
        {
          /* istanbul ignore next */ includeVenmo && includeSmartPay ? (
            <ListItem
              className="mb-16"
              onClick={onVenmoClick}
              startIcon={<PaymentIcon height={42} paymentMethodType="venmo" />}
              title={paymentMethodStrings.add.labels.venmo}
              titleProps={{ primary: true }}
            />
          ) : null
        }
        <ListItem
          className="mb-16"
          onClick={onAddCardClick}
          startIcon={
            <PaymentIcon height={42} paymentMethodType="credit card" />
          }
          title={paymentMethodStrings.add.labels.card}
          titleProps={{ primary: true }}
        />
      </div>
      {
        /* istanbul ignore next */ includePayPal && includeSmartPay ? (
          <div className="list add-payment-method">
            <p className="inline-list-instructions mb-16 uppercase">
              {paymentMethodStrings.add.instructions.continueWith}
            </p>
            <ListItem
              onClick={onPayPalClick}
              startIcon={<PaymentIcon height={42} paymentMethodType="PayPal" />}
              title={paymentMethodStrings.add.labels.payPal}
              titleProps={{ primary: true }}
            />
          </div>
        ) : null
      }
    </div>
  );
}
