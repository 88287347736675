/**
 * @module Callback
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '../../hooks/useAuth';
import useGiving from '../../hooks/useGiving';
import Loading from '../../assets/svg/loading.svg';
import LifeChurchLogoHorizontal from '../../assets/svg/lc-logo-horizontal.svg';
import { CALLBACKS, STRINGS } from '../../utils';
import '../../styles/main.scss';
import './Callback.scss';

/**
 * Represents a view presented to the user when authentication callback is being processed.
 *
 * @returns {React.ReactElement} The Callback component.
 */
function Callback() {
  const { handleAuthentication } = useAuth();
  const { redirectToMainPage } = useGiving();

  /**
   * Handler function for Auth0 callback path.
   *
   * @returns {Function} Function to handle user path to next page in flow.
   */
  function handleAuthPath() {
    /* istanbul ignore next */
    if (!/access_token|id_token|error/.test(window.location.hash)) {
      return redirectToMainPage();
    }
    return handleAuthentication();
  }

  /**
   * Single-run convenience effect to trigger next step handling.
   * Important: For Auth0, double-checking inclusion of one of the required hash
   * variables to be present in the URL. If not found, redirect to main page.
   */
  React.useEffect(() => {
    let { pathname } = window.location;

    if (pathname.endsWith('/')) {
      pathname = pathname.substring(0, pathname.length - 1);
    }

    /* istanbul ignore next */
    if (pathname === CALLBACKS.auth0.path) {
      handleAuthPath();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container" data-testid="callback-view">
      <div className="content-center">
        <div className="callback flex-center">
          <img
            alt={STRINGS.general.lifeChurch}
            className="lc-logo"
            src={LifeChurchLogoHorizontal}
          />
          <img alt={STRINGS.labels.loading} src={Loading} />
        </div>
      </div>
    </div>
  );
}

export default Callback;
